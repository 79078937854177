<template>
  <div>
    <div class="gps-box">
      <div class="gps-title">{{ $t("map.cheliangshebei") }}</div>
      <div class="gps-body">
        <div
          id="clsb"
          style="width: 500px; height: 360px; margin-left: 10px"
        ></div>
      </div>
    </div>
    <div class="gps-box">
      <div class="gps-title">{{ $t("map.cheliangpingfen") }}</div>
      <div class="gps-body">
        <div
          id="clpf"
          style="width: 500px; height: 360px; margin-left: 10px"
        ></div>
      </div>
    </div>
    <div class="gps-box">
      <div class="gps-title">{{ $t("map.lichengtongji") }}</div>
      <div class="gps-body">
        <div
          id="lctj"
          style="width: 500px; height: 360px; margin-left: 10px"
        ></div>
      </div>
    </div>
    <div class="gps-box">
      <div class="gps-title">{{ $t("map.baojingtongji") }}</div>
      <div class="gps-body">
        <div
          id="bjtj"
          style="width: 500px; height: 360px; margin-left: 10px"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {};
  },
  mounted() {
    this.getCLSB();
    this.getCLPF();
    this.getLCTJ();
    this.getBJTJ();
  },
  methods: {
    getBJTJ() {
      var myChart = echarts.init(document.getElementById("bjtj"));
      this.$api.search_get_main_result({}).then((res) => {
        if (res.data.result == 1) {
          let ec_data = [
            res.data.e103,
            res.data.e101,
            res.data.e3,
            res.data.e30,
            res.data.e1,
            res.data.e2,
          ];
          console.log(ec_data);
          var option = {
            tooltip: {
              trigger: "item",
              formatter: "{b} : {c} 个",
            },
            xAxis: {
              type: "category",
              data: [
                this.$t("map.cipanbaojing"),
                this.$t("map.shipinbaojing"),
                this.$t("map.pilaobaojing"),
                this.$t("map.a_30"),
                this.$t("map.sosbaojing"),
                this.$t("map.a_2"),
              ],
              axisLine: {
                lineStyle: {
                  color: "#fd5454",
                },
              },
              axisLabel: {
                // color:"#fff",
                interval: 0, //横轴信息全部显示
                // rotate: -30,//-30度角倾斜显示
              },
            },
            yAxis: {
              type: "value",
              axisLine: {
                lineStyle: {
                  color: "#fd5454",
                },
              },
              axisLabel: {
                color: "#fff",
              },
            },
            series: [
              {
                data: ec_data,
                type: "bar",
                itemStyle: {
                  color: "#fd5454",
                },
              },
            ],
          };
          myChart.setOption(option);
        }
      });
    },
    getLCTJ() {
      var myChart = echarts.init(document.getElementById("lctj"));
      this.$api.sys_get_car_lctj({}).then((res) => {
        if (res.data.result == 1) {
          var option = {
            tooltip: {
              trigger: "axis",
            },
            calculable: true,
            xAxis: [
              {
                type: "category",
                data: res.data.cph,
              },
            ],
            yAxis: [
              {
                type: "value",
                name: res.data.u,
              },
            ],
            series: [
              {
                name:this.$t("map.fanyi15"),
                type: "bar",
                data: res.data.licheng,
                itemStyle: { normal: { color: "#f0b700" } },
                barWidth: "58px",
              },
            ],
          };
          myChart.setOption(option);
        }
      });
    },
    getCLPF() {
      var myChart = echarts.init(document.getElementById("clpf"));
      this.$api.sys_get_car_clpf({}).then((res) => {
        if (res.data.result == 1) {
          var option = {
            tooltip: {
              trigger: "axis",
            },
            calculable: true,
            xAxis: [
              {
                type: "category",
                data: res.data.vPlate_list,
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: this.$t("map.cheliangpingfen"),
                type: "bar",
                data: res.data.vScore_lists,
                itemStyle: { normal: { color: "#36c2d3" } },
                barWidth: "58px",
              },
            ],
          };
          myChart.setOption(option);
        }
      });
    },
    getCLSB() {
      var myChart = echarts.init(document.getElementById("clsb"));
      this.$api.sys_get_main_result({}).then((res) => {
        if (res.data.result == 1) {
          var option = {
            tooltip: {
              trigger: "item",
              formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            legend: {
              bottom: 2,
              left: "center",
            },
            series: [
              {
                name: "devices",
                type: "pie",
                radius: "65%",
                center: ["50%", "50%"],
                selectedMode: "single",
                data: [
                  {
                    value: res.data.spsbzs_num,
                    name: this.$t("map.shipinshebeizongshu")+ ":" + res.data.spsbzs_num,
                    itemStyle: { color: "#4caf50" },
                  },
                  {
                    value: res.data.zzqzs_num,
                    name: this.$t("map.zhuizongqizongshu") + ":" + res.data.zzqzs_num,
                    itemStyle: { color: "#585eaa" },
                  },
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              },
            ],
          };
          myChart.setOption(option);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gps-box {
  width: 50%;
}
</style>
